<template>
  <v-card :class="reuseComponent === true ? 'elevation-0' : ''">
    <v-card-title class="primary white--text" v-if="!reuseComponent">
      <span class="title">Add/Update</span>
    </v-card-title>
    <v-card-text :class=" reuseComponent === true ? 'pb-1 pl-0 pr-0' : 'pb-0 mt-10'">
      <!-- <v-form ref="form" v-model="valid" :lazy-validation="lazy"> -->
        <v-calendar-field
          id-val="personal_event"
          v-model="personal_event.personal_event_date"
          label="Personal Event*"
          name="event_date"
          v-if="!reuseComponent"
        >
        </v-calendar-field>

        <v-textarea
          autocomplete="off"
          label="Description *"
          class="pa-0 mt-2"
          outlined
          dense
          no-resize
          v-model="personal_event.description"
          name="description"
                  />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="(save_flag = true), reset()"
            >Close</v-btn
          >
          <v-btn color="success" text v-if="save_flag" @click="save()"
            >Save</v-btn
          >
          <v-btn color="success" text @click="update()" v-else>Update</v-btn>
        </v-card-actions>
      <!-- </v-form> -->
    </v-card-text>
  </v-card>
</template>
<script>
const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
export default {
  props: {
    edit_data: {
      default: null,
    },
    reuseComponent:{
        default:false,
    },
    event_date:{
        default:null
    }
  },
  data: () => ({
    valid: true,
    lazy: false,
    save_flag: true,
    personal_event :{
        dialog: false,
        description: "",
        status: 0,
        personal_event_date: new NepaliDate(new Date()).format("YYYY-MM-DD"),
    },
    // form: new Form(
    //   {
    //     dialog: false,
    //     description: "",
    //     status: 0,
    //     personal_event_date: new NepaliDate(new Date()).format("YYYY-MM-DD"),
    //   },
    //   "/api/personal-event"
    // ),

    headers: [
      { text: "#", align: "left", value: "id", sortable: false },
      {
        text: "Description",
        align: "left",
        value: "description",
        sortable: false,
      },
      { text: "Status", align: "left", value: "status", sortable: false },
      {
        text: "Date",
        align: "left",
        value: "personal_event_date",
        sortable: false,
      },

      { text: "Action", align: "right", sortable: false },
    ],
  }),
  mounted(){
    if(this.event_date !== null){
        this.personal_event.personal_event_date = this.event_date;
    }
    if(this.edit_data !== ""){
        this.save_flag = false
        this.personal_event.description = this.edit_data.description
        this.personal_event.personal_event_date = this.edit_data.personal_event_date
    }
  },
  methods: {
    
    // save() {
    //   if (this.$refs.form.validate()) {
    //     this.form.store().then((res) => {
    //       this.reset();
    //     });
    //   }
    // },
    save(){
        this.$rest.post("/api/personal-event",{
            description:this.personal_event.description,
            personal_event_date:this.personal_event.personal_event_date
        })
        .then((res)=>{
            if(res.status == 200){
                this.reset();
                this.$events.fire("notification", {
                    message: res.data.message,
                    status: "success",
                });
            }
        }).catch((err)=>{
            this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        })
    },
    update(){
        this.$rest.put("/api/personal-event/"+this.edit_data.id,{
            description:this.personal_event.description,
            personal_event_date:this.personal_event.personal_event_date
        }).then((res)=>{
            this.reset();
            this.$events.fire("notification", {
                message: res.data.message,
                status: "success",
            });
        }).catch((err)=>{
            this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        })
    },
    
    reset() {
      this.personal_event.description = "";
      this.personal_event.status = 0;
      this.personal_event.personal_event_date = new NepaliDate(new Date()).format(
        "YYYY-MM-DD"
      );
      this.save_flag = true;
      this.$emit("reset");
    },
  },
//   watch:{
//     'edit_data':function(v){
//         console.log(v,'val')
//         if(v){
//             this.save_flag = false;
//             this.personal_event.description = v.description,
//             this.personal_event.personal_event_date = v.personal_event_date

//         }
//     }
//   }
};
</script>
